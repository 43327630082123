"use strict";
exports.__esModule = true;
exports.Application = void 0;
var static_assets_1 = require("./static.assets");
var Application = /** @class */ (function () {
    function Application() {
    }
    Application.prototype.run = function () {
        this.initStaticAssets();
    };
    Application.prototype.initStaticAssets = function () {
        new static_assets_1.StaticAssets().run();
    };
    return Application;
}());
exports.Application = Application;
